import React from "react";
import Data from "./comp/Data";
import Hero from "./comp/Hero";

export default function Product() {
  return (
    <div className="w-full h-full">
      <Hero />
      <Data />
    </div>
  );
}
