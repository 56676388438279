import React from "react";
import { GIF } from "../../asset";

export default function NoPage() {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <img src={GIF.not_found} alt="404 gif" />
    </div>
  );
}
