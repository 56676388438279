import React from "react";
import { IMG } from "../../asset";
export default function Contact() {
  return (
    <div className="w-full px-5 h-auto md:h-[82vh] grid grid-cols-1 md:grid-cols-2 gap-2">
      <div className="h-full w-full flex justify-center">
        <img
          src={IMG.contact}
          alt="about"
          className="w-[70%] object-contain p-2 md:p-0"
        />
      </div>
      <div className="h-auto w-full flex flex-col items-center justify-center xl:pr-30 lg:pr-15">
        <div className="h-auto md:h-[15%] w-full pt-1 pl-2">
          <p className="text-black text-lg md:text-xl xl:text-3xl lg:text-2xl p-0 m-0">
            Contact Us
          </p>
          <p className="text-dark text-[12px] md:text-[8px] lg:text-[14px] xl:text-[16px] md:mb-0 lg:mb-7 font-base m-0 p-0">
            Tell us more about yourself so we can get in touch with you
          </p>
        </div>
        <div className="h-auto md:h-[85%] w-full">
          <div class="w-full px-2">
            <div class="row-span-1 col-span-1">
              <p className="text-[12px]  md:text-[8px] lg:text-[12px] xl:text-[18px] text-gray font-medium pt-1">
                Your name
              </p>
              <input
                className="rounded-sm border border-solid border-1 border-border outline-none text-sm md:text-[8px] lg:text-sm xl:lg:text-base w-full h-[25px] md:h-[20px] lg:h-[35px] px-2"
                type="text"
                placeholder="Enter your name"
              />
            </div>
            <div class="row-span-1 col-span-1">
              <p className="text-[12px] md:text-[8px] lg:text-[12px] xl:text-[18px] text-gray font-medium pt-1">
                Your email
              </p>
              <input
                className="rounded-sm border border-solid border-1 border-border outline-none text-sm  md:text-[8px] lg:text-sm xl:lg:text-base w-full h-[25px] md:h-[20px] lg:h-[35px] px-2"
                type="text"
                placeholder="Enter your email"
              />
            </div>
            <div class="row-span-2 col-span-1">
              <p className="text-[12px] md:text-[8px] lg:text-[12px] xl:text-[18px]  text-gray font-medium pt-1">
                Message
              </p>
              <textarea
                name="postContent"
                className=" border border-1 border-border h-auto w-full text-sm  md:text-[8px] xl:lg:text-base lg:text-sm px-2 outline-none"
                rows={3}
              />
            </div>
          </div>

          <div className="w-full flex py-1 lg:py-2">
            <div className=" w-12  flex justify-center items-center ">
              <input type="checkbox" name="" id="" />
            </div>
            <div className="w-full">
              <p className="text-dark text-[8px] md:text-[6px] lg:text-[10px] xl:text-[16px] font-normal ">
                By checking this box and submitting your information you are
                giving us permission to email you .You may unsubscribe at any
                time.
              </p>
            </div>
          </div>
          <div class="w-auto px-2">
            <button class="button px-10 md:px-5 lg:px-10 py-1 md:py-0 lg:py-1 bg-button text-white">
              <span>
                <p class="text-base md:text-[8px] lg:text-base xl:text-lg font-semibold">
                  SUBMIT
                </p>
              </span>
            </button>

            <div className="h-auto flex justify-end  w-full px-2 xl:mt-2">
              <div className="h-auto flex flex-col items-start justify-center">
                <p className="text-dark text-sm md:text-[8px] xl:text-[14px]">
                  <a href="mailto:tei@essential-invest.com">
                    TEI
                    <span className="text-base md:text-[10px] xl:text-[16px]">@</span>
                    ESSENTIAL-INVEST.COM
                  </a>
                </p>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>
  );
}
