import React from "react";
import UnderDev from "../../components/underDev/UnderDev";

export default function Hustles() {
  return (
    <div className="w-full h-screen">
      <UnderDev />
    </div>
  );
}
