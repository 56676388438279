import React from "react";
import { ICON } from "../../../asset";

export default function Data() {
  const points = [
    {
      id: 1,
      txt: "Finds & screens investments",
      txt1: "1",
    },
    {
      id: 2,
      txt: "Communicates with and gathers interested TEI investors",
      txt1: "2",
    },
    {
      id: 3,
      txt: "Performs a thorough investment evaluation ",
      txt1: "3",
    },
    {
      id: 4,
      txt: "Collects commitments and executes the deal ",
      txt1: "4",
    },
    {
      id: 5,
      txt: "Manages the ongoing accounting, banking, and legal needs until the investment is closed and all returns are distributed ",
      txt1: "5",
    },
  ];

  return (
    <div className="h-auto w-full flex justify-center items-center ">
      <div className="h-full w-[100%] grid md:grid-cols-2 sm:grid-cols-1">
        <div className="w-full h-full flex items-start">
          <div className="text-center w-full">
            <div className="flex justify-center">
              <div className="w-full border-t-2 border-dark flex justify-center mt-[10px] md:mt-0">
                <p className="text-white bg-dark font-black text-[17px] md:text-[12px] xl:text-[17px] 2xl:w-auto 2xl:text-[2vh]  w-[300px] text-center  2xl:px-2">
                  Role of the General Partner (GP)
                </p>
              </div>
            </div>
            <div className="flex relative justify-center mt-0 lg:pt-0 md:pt-2">
              <div className="hidden md:block absolute right-0 top-0 h-[90%] w-[3px] bg-black"></div>
              <div className="w-auto h-full flex justify-center items-center sm:items-start flex-col col-span-6 px-3">
                {points.map((data) => (
                  <div
                    className="flex items-start py-1 md:py-[2px] lg:py-1  w-full"
                    key={data.id}
                  >
                    <div className="bg-primary rounded-full w-[15px] h-[15px] md:w-[10px] md:h-[10px] xl:w-[18px] xl:h-[18px] 2xl:h-[2vh] 2xl:w-[2vh] flex items-center justify-center xl:mt-0.5 md:mt-0 mt-0.5">
                      <p className="text-white text-[8px] md:text-[5px] xl:text-[8px] 2xl:text-[1vh]">
                        {data.txt1}
                      </p>
                    </div>
                    <p className="text-dark text-start text-[12px] md:text-[10px]  lg:text-[10px] xl:text-[14px] px-2 w-[90%] font-bold 2xl:text-[1.9vh] leading-relaxed">
                      {data.txt}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center w-full">
          <div className="flex justify-center ">
            <div className="w-full border-t-2 border-dark flex justify-center mt-[10px] md:mt-0">
              <p className="text-white bg-dark font-black text-[17px] md:text-[12px] xl:text-[17px] w-[210px] text-center  2xl:w-auto 2xl:text-[2vh] 2xl:px-2">
                TEI Structure
              </p>
            </div>
          </div>
          <div className="flex justify-center border-dark md:pl-4 lg:pl-0 h-[87%]">
            <div className=" w-full h-full grid sm:grid-cols-8 grid-cols-1 ">
              <div className="flex h-auto  items-center justify-center col-span-2 md:col-span-4 lg:col-span-4 w-full pt-2 md:pt-0">
                <img
                  src={ICON.Full}
                  alt="user"
                  className="w-full h-[150px] lg:h-[100px] md:h-[80px] xl:h-[150px] 2xl:h-[18vh] object-contain "
                />
              </div>
              <div className="w-auto h-full text-center md:text-start flex justify-center items-start  flex-col col-span-6 md:col-span-4 lg:col-span-4 px-3 font-bold">
                <p className="text-[12px] md:text-[10px]  lg:text-[12px] xl:text-[18px] 2xl:text-[2vh] leading-relaxed">
                  Each TEI investment is its own separate limited partnership
                  (LP). This structure protects our investors
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
