import React from "react";
import Hero from "./comp/Hero";
import Data from "./comp/Data";

export default function Home() {
  return (
    <div className="h-full w-full">
      <Hero />
      <Data />
    </div>
  );
}
