import React from "react";
import { IMG } from "../../asset";
import UnderDev from "../../components/underDev/UnderDev";

export default function Partners() {
  return (
    <div className="w-full h-auto flex items-center justify-center">
    {/* <div className="w-full h-[72vh] md:h-auto flex items-start"> */}
      {/* <img src={IMG.partners} alt="partners" className="w-full object-contain" /> */}
      <UnderDev />
    </div>
  );
}
