export const LOGO = {
  logo: require("./logo/logo.png"),
};
export const ICON = {
  top: require("./top.png"),
  chain: require("./chain.png"),
  Full: require("./Full.png"),
  point: require("./point.png"),
  right: require("./right.png"),
};
export const IMG = {
  hero: require("./hero.jpeg"),
  hero2: require("./hero2.png"),
  about: require("./about1.jpg"),
  // about: require("./aboutt.jpg"),
  ab2: require("./ab2.png"),
  ab3: require("./ab3.png"),
  ab4: require("./ab4.png"),
  us1: require("./us1.jpg"),
  us2: require("./us2.png"),
  us3: require("./us3.jpeg"),
  us4: require("./us4.png"),
  in1: require("./in1.png"),
  in2: require("./in2.png"),
  in3: require("./in3.png"),
  pro: require("./ip.jpg"),
  // pro: require("./investportal.jpg"),
  in4: require("./in4.png"),
  contact: require("./contact.jpg"),
  partners: require("./partners.jpg"),
};
// export const CAROUSEL = {
//   hero1: require("./home/tech.png"),
//   hero2: require("./home/bee.png"),
//   hero3: require("./home/utility.png"),
//   hero4: require("./home/health.png"),
//   hero5: require("./home/gal.png"),
//   hero6: require("./home/storage.png"),
//   hero7: require("./home/land.png"),
// };
export const CAROUSEL = {
  hero1: require("./home/tech1.png"),
  hero2: require("./home/bee2b.jpg"),
  hero3: require("./home/utility1.png"),
  hero4: require("./home/health2b.jpg"),
  hero5: require("./home/gal2b.jpg"),
  hero6: require("./home/storage1.png"),
  hero7: require("./home/land1.png"),
};
export const GIF = {
  not_found: require("./7iJU.gif"),
  under_dev: require("./79S2.gif"),
};
export const PROD = {
  p1: require("./portel/1a.png"),
  p2: require("./portel/2.png"),
  p3: require("./portel/3a.png"),
  p4: require("./portel/4a.png"),
  p5: require("./portel/5.png"),
  p6: require("./portel/6.png"),
  p7: require("./portel/7a.png"),
  p8: require("./portel/8a.png"),
  p9: require("./portel/9a.png"),
  p10: require("./portel/10.png"),
  p11: require("./portel/11b.png"),
  p12: require("./portel/12.png"),
  p13: require("./portel/13c.png"),
  p14: require("./portel/14a.png"),
};
