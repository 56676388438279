import React from "react";
import { CAROUSEL } from "../../../asset";
import { Carousel } from "react-responsive-carousel";

export default function Hero() {
  const array = [
    {
      id: 1,
      img: CAROUSEL.hero3,
      txt1: "Ultra high net worth and institutional capital ",
      txt2: "put ~20% of their wealth into Private Market investments.",
      txt3: " How much do you?",
    },
    {
      id: 2,
      img: CAROUSEL.hero2,
      txt1: "85% of the 21,000 US companies valued",
      txt2: " at over $100 Million are Private",
      txt3: "",
      
    },
    {
      
      id: 3,
      img: CAROUSEL.hero1,
      txt1: "99% of US companies are Private",
      txt2: "",
      txt3: "",
    },
    {
      id: 4,
      img: CAROUSEL.hero4,
      txt1: "TEI invests in 5 to 10 ",
      txt2: "Private Market deals each year",
      txt3: "",
    },
    {
      id: 5,
      img: CAROUSEL.hero5,
      txt1: "We invest in established businesses and ",
      txt2: "owners that are tied to our community",
      txt3: "",
    },
    {
      id: 6,
      img: CAROUSEL.hero6,
      txt1: "We target 15%+ Annual Returns and ",
      txt2: "investment payouts within 7 years",
      txt3: "",
    },
    {
      id: 7,
      img: CAROUSEL.hero7,
      txt1: "From 2000 - 2020 the Private Market outperformed the ",
      txt2: "Public Market by almost DOUBLE (10.5% vs 5.9%)",
      txt3: "",
    },
  ];
  return (
    <div className="w-full h-auto">
      <Carousel
        className="w-full h-full"
        showArrows={true}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
        interval={5000}
        transitionTime={700}
        useKeyboardArrows={true}
      >
        {array.map((data) => (
          <div className="h-auto  lg:h-[57vh] relative w-full " key={data.id}>
            {/* <div className="h-auto relative w-full " key={data.id}> */}
            <div className=" w-full h-full">
              <img
                src={data.img}
                alt="carosel"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="absolute w-full h-[100%] top-0 bg-black opacity-40"></div>
            <div className="absolute w-full top-0 h-full flex flex-col justify-center items-end">
              <p className="text-white text-[10px]  sm:text-[15px] md:text-3xl 2xl:text-[4vh] font-bold pr-7 w-[90%] md:w-[60%] text-end  lg:mb-1">
                {data.txt1}
              </p>
              <p className="text-white  text-[10px]  sm:text-[15px] md:text-3xl  2xl:text-[4vh] font-bold pr-7 w-[90%] md:w-[60%] text-end  lg:mb-1">
                {data.txt2}
              </p>
              <p className="text-white  text-[10px]  sm:text-[15px] md:text-3xl  2xl:text-[4vh] font-bold pr-7 w-[90%] md:w-[60%] text-end  lg:mb-1">
                {data.txt3}
              </p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
