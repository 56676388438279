import React from 'react'
import {  IMG } from '../../../asset'

export default function Hero() {
  return (
    <div className="w-full h-auto">
      <img src={IMG.pro} alt="about" className="w-full h-full object-cover" />
    </div>
  )
}
