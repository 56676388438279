import React from "react";
import Hero from "./comp/Hero";
import Data from "./comp/Data";
import Team from "./comp/Team";

export default function About() {
  return (
    <div className="w-full h-full">
      <Hero />
      <Data />
      <Team />
    </div>
  );
}
