import React from "react";
import { GIF } from "../../asset";

export default function UnderDev() {
  return (
    // <div className="w-auto h-full flex flex-col justify-center items-center ">
    //   <img className="w-full object-cover" src={GIF.under_dev} alt="dev gif" />
    <div className="w-full h-full flex flex-col justify-center items-center ">
      <img className="w-full 2xl:h-[78vh] lg:h-[76vh] md:h-[74vh] h-auto object-contain" src={GIF.under_dev} alt="dev gif" />
      <p className="text-xl font-semibold text-primary">
        Page Under Development
      </p>
    </div>
  );
}
